import React from 'react';
import { RootState } from '../../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
// import { ReactComponent as TicketIcon } from '../../img/vticket-24px.svg';
import { ReactComponent as ArrowUp } from '../../img/arrow-up-24px.svg';
import { ReactComponent as ArrowDown } from '../../img/arrow-down-24px.svg';
import { toggleTicketExpanded } from '../../store/ticket/ticket-actions';
import { sendTicketToApp } from '../../store/thunks';
import { useDispatch } from 'react-redux';
import Spinner from '../Spinner';
// import Spinner from '../Spinner';

const PreparedVirtualTicketsHeader: React.FC<ReduxProps> = (props) => {
  const {
    toggleExpanded,

    isExpanded,
    isEmpty,
    preparedTickets,
    hasApp,
    hasPrinter,
    sendingTicketsToApp,
    errorSendingTicketToApp,
  } = props;

  const dispatch = useDispatch();
  const showSendToApp = !isEmpty && isExpanded && hasApp;

  const handleExpand = () => {
    toggleExpanded();
  };

  const handleSendToApp: React.MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('send to app');
    if (sendingTicketsToApp) {
      return;
    }
    dispatch(sendTicketToApp());
  };

  const pluralSuffix = preparedTickets.length !== 1 ? 'e' : '';
  const countPreparedTicketsString = `${preparedTickets.length} Bilet${pluralSuffix} pregătit${pluralSuffix}`;
  const totalPayment = preparedTickets
    .map((ticket) => {
      return ticket.miza;
    })
    .reduce((total, current) => {
      return total + current;
    }, 0);

  const totalPaymentString =
    totalPayment === 0
      ? ''
      : ` / Total de plată: ${totalPayment.toFixed(2)} lei`;
  return (
    <div className='ticket-header' onClick={handleExpand}>
      <div style={{ display: 'inline-block', marginRight: 16 }}>
        {isExpanded && <ArrowDown />}
        {!isExpanded && <ArrowUp />}
      </div>
      <div className='v-stack margin-r-36'>
        <span className='ticket-header-label'>
          {countPreparedTicketsString}
          {totalPaymentString}
        </span>
      </div>

      <div className='flex-spacer' />

      {showSendToApp && (
        <>
          {sendingTicketsToApp && <Spinner fill='#212121' />}
          {!sendingTicketsToApp && (
            <div
              className='ticket-button text-center'
              onClick={handleSendToApp}
            >
              <span className='inline-block '>Continuă</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    isExpanded,
    preparedTickets,
    ticketsToApp: { isLoading, error },
  } = state.virtualTicket;
  const { printingMode } = state.system.config;
  return {
    isExpanded,
    preparedTickets,
    isEmpty: preparedTickets.length === 0,
    hasApp: printingMode === 'app',
    hasPrinter: printingMode === 'printer',
    sendingTicketsToApp: isLoading,
    errorSendingTicketToApp: error,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
    toggleExpanded: () => dispatch(toggleTicketExpanded()),
  };
};
const connector = connect(mapStateToProps, mapDispatchActions);

type ReduxProps = ConnectedProps<typeof connector>;

const ConnectedPreparedVirtualTicketsHeader = connector(
  PreparedVirtualTicketsHeader
);
export default ConnectedPreparedVirtualTicketsHeader;
