import React, {
  useCallback,
  useEffect,
  useState,
  MouseEventHandler,
  useLayoutEffect,
} from 'react';

import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
// import { debounce } from 'lodash';
import { RootState } from '../store';

import * as selectors from '../store/selectors';

// import { startSession } from '../store/system/actions';
import { BetType, SelectedOfferData } from '../store/offer/types';
// import { wsMessage } from '../store/system/actions';
import TouchscreenPage, { Page } from './TouchscreenPage';
import TournamentNavigator from './TournamentNavigator';
import { motion, AnimatePresence } from 'framer-motion';
interface Props {
  isTouchscreen: boolean;
}

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const VirtualOfferContentTouchScreen: React.FC<Props> = (props: Props) => {
  // const { , appId, sessionId, date } = props;

  const [canScrollRight, setCanScrollRight] = useState(true);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const ul = React.useRef<HTMLDivElement>(null);

  const [visiblePages, setVisiblePages] = useState(4);
  const data = useSelector((state: RootState) => state.offer.selectedOffer);
  const category = useSelector(selectors.getSelectedCategory);

  const categoryName = category?.name.toUpperCase() || '';
  const lastUpdate = React.useRef(Date.now());
  const lastScroll = React.useRef(0);

  // const lastScroll = React.useRef(0);
  const [pages, setPages] = useState<Page[]>([]);
  const [loading, setLoading] = useState(true);
  const updateNavigation = React.useRef(true);

  const makePages = useCallback((data: SelectedOfferData | undefined) => {
    if (data === undefined) {
      setPages([]);
      return 0;
    }
    const page_id_prefix = `p${Date.now()}`;
    const TH = 36 + 2;
    const TH_MARGIN_TOP = 8;
    const BET_MARGIN_BOTTOM = 3;
    const REAL_TH_MARGIN_TOP = TH_MARGIN_TOP - BET_MARGIN_BOTTOM;
    const BETH = 34 + BET_MARGIN_BOTTOM;
    const MAX_PAGE_HEIGHT = 940;
    const MIN_T_HEIGHT = TH_MARGIN_TOP + TH + 2 * BETH + REAL_TH_MARGIN_TOP;

    const layout: Page[] = [];

    let currentHeight = 0;
    let currentPageIndex = 0;
    let currentX = 0;
    let page: Page = {
      id: `${page_id_prefix}-${currentPageIndex}`,
      index: currentPageIndex,
      offset: 0,
      size: 0,
      hidden: false,
      tournaments: [],
      // bets: [],
    };

    const makeNewPage = () => {
      layout.push(page);
      currentPageIndex += 1;
      currentX += layout.length === 0 ? 0 : layout[layout.length - 1].size + 8;
      currentHeight = 0;
      page = {
        id: `${page_id_prefix}-${currentPageIndex}`,
        index: currentPageIndex,
        offset: currentX,
        size: 0,
        hidden: false,
        tournaments: [],
        // bets: [],
      };
      // console.log(`make new page ${currentPageIndex}`);
    };

    const eventsFittingSpace = (h: number): number => {
      return Math.floor(h / BETH);
    };

    for (let i = 0; i < data.tournaments.length; i++) {
      const tournament = data.tournaments[i];
      let eventsToFit = tournament.totalBets;

      const totalSpaceNeeded = TH + TH_MARGIN_TOP + eventsToFit * BETH;

      if (
        MAX_PAGE_HEIGHT - currentHeight <
        Math.min(totalSpaceNeeded, MIN_T_HEIGHT)
      ) {
        makeNewPage();
      }

      currentHeight += TH + REAL_TH_MARGIN_TOP;
      const allBets = tournament.bets.slice();
      let showTH = true;
      while (eventsToFit > 0) {
        const eventsToAdd = eventsFittingSpace(MAX_PAGE_HEIGHT - currentHeight);
        const eventsAdded = Math.min(eventsToAdd, eventsToFit);
        eventsToFit -= eventsToAdd;
        currentHeight += eventsAdded * BETH;

        page.tournaments.push({
          id: tournament.id,
          name: tournament.name,
          type: tournament.type,
          bets: allBets.splice(0, eventsAdded),
          showTH,
        });

        const noOdds = tournament.type.odds.length;
        const size = 783; // bet_widths[noOdds - 1];

        page.size = Math.max(page.size, size);
        if (showTH) {
          showTH = false;
        }
        if (eventsToFit > 0) {
          makeNewPage();
        }
      }
    }
    if (page.tournaments.length > 0) {
      layout.push(page);
    }

    setPages(layout);
    // console.debug(`Made ${layout.length} pages`);

    return layout.length;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      updateNavigation.current = true;
      makePages(data);
      lastUpdate.current = Date.now();
      setVisiblePages(4);
      setLoading(false);
      ul.current?.scrollTo({ left: 0 });
    }, 1);

    return () => {
      setLoading(true);
      // setPages([]);
      //setVisiblePages(0);
      // if (that_ul) {
      //   console.log('will scroll');
      //   that_ul.scrollLeft = 0;
      // }
    };
  }, [data, makePages]);

  // const updateInitialPages = () => {
  //   const totalPages = pages.length;
  //   const pagesToShow = Math.min(Math.max(visiblePages, 3), totalPages);
  //   setVisiblePages(pagesToShow);
  // };

  useLayoutEffect(() => {
    console.log('layout effect');
    if (updateNavigation.current === true) {
      updateNavigation.current = false;
      setCanScrollLeft(false);
      setCanScrollRight(pages.length > 1);
    }
  }, [pages, visiblePages]);

  const EmptyOfferPlaceholder = (
    <div style={{ color: '#fff' }}>Empty Offer</div>
  );

  const logTSScroll = useCallback(
    (left: number) => {
      console.log('scrolling..');
      if (left === lastScroll.current) {
        return;
      }
      lastScroll.current = left;
      // dispatch(
      //   wsMessage({
      //     appId,
      //     sessionId,
      //     isTouchscreen: true,
      //     rx_action: {
      //       type: 'WILL_SCROLL',
      //       left,
      //       c_id: category?.id,
      //       c_name: category?.name,
      //       date,
      //     },
      //     dt: 0,
      //   })
      // );
    },
    // [appId, sessionId, dispatch]
    []
  );

  useEffect(() => {
    console.log(lastUpdate.current + ' visiblePages: ', visiblePages);
  }, [visiblePages]);

  const debouncedTSLogScroll = useCallback(debounce(logTSScroll, 300), [
    logTSScroll,
    // sessionId,
  ]);
  const handleContainerScroll = debounce(
    useCallback(
      (event?: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (!ul.current) {
          return;
        }
        console.log('handleContainerScroll');
        const theul = ul.current;

        const ol = theul.scrollLeft;
        const sw = theul.scrollWidth;
        const w = theul.offsetWidth;
        const newCanScrollRight = ol + w < sw;
        const newCanScrollLeft = ol > 0;

        setCanScrollLeft(newCanScrollLeft);
        setCanScrollRight(newCanScrollRight);

        const maxVisiblePageOffset = ol + 2 * w;
        if (visiblePages < pages.length) {
          const newIndex = pages.findIndex(
            (p) => p.offset > maxVisiblePageOffset
          );
          if (newIndex < 0) {
            setVisiblePages(pages.length);
          } else if (newIndex >= visiblePages) {
            setVisiblePages(newIndex + 1);
          }
        }

        debouncedTSLogScroll(ol);
      },
      [debouncedTSLogScroll, pages, visiblePages]
    ),
    200
  );

  const tryScroll = (up: boolean) => {
    if (!ul.current) {
      return;
    }
    const theul = ul.current;

    const ol = theul.scrollLeft;
    const options: ScrollToOptions = { behavior: 'smooth' };

    if (!up) {
      const columnIndex = pages.findIndex((p) => p.offset > ol);
      if (columnIndex >= 0) {
        options.left = pages[columnIndex].offset;
        theul.scrollTo(options);
      }
    } else {
      const columnIndex = pages.findIndex((p) => p.offset >= ol);
      if (columnIndex > 0) {
        options.left = pages[columnIndex - 1].offset;
        theul.scrollTo(options);
      }
    }
  };

  const handleScrollToTournament = (tournament_id: string) => {
    const targetPage = pages.findIndex(
      (page) => page.tournaments.findIndex((t) => t.id === tournament_id) >= 0
    );
    if (targetPage >= 0) {
      const options: ScrollToOptions = {
        behavior: 'smooth',
      };

      const page = pages[targetPage].offset;
      options.left = page ?? 0;
      ul.current?.scrollTo(options);
    }

    // document.querySelector('.content-wrapper')?.scrollTo(options);
  };

  return (
    <>
      <TournamentNavigator
        isTouchscreen={true}
        onScroll={handleScrollToTournament}
      />

      <AnimatePresence>
        {!loading && (
          <motion.div
            ref={ul}
            initial={{ opacity: 0.85 }}
            animate={{
              opacity: 1,
              transition: { ease: 'easeIn', duration: 0.4 },
            }}
            exit={{
              opacity: 0,
              transition: { ease: 'easeOut', duration: 0.3 },
            }}
            className='cwt'
            onScroll={handleContainerScroll}
            key={`tsc${loading ? '1' : '0'}`}
          >
            {!loading && pages.length === 0 && EmptyOfferPlaceholder}

            {pages.length > 0 &&
              pages.map((page) => (
                <TouchscreenPage
                  page={page}
                  key={page.id}
                  isVisible={page.index < visiblePages}
                  categoryName={categoryName}
                ></TouchscreenPage>
              ))}
          </motion.div>
        )}
      </AnimatePresence>
      {/* navigation buttons */}
      <div id='offer-scroll-ct' className={'scroll-controller'}>
        <div
          className={`scroll-up ${canScrollLeft ? 'active' : 'disabled'}`}
          onClick={() => {
            tryScroll(true);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <g id='arrow-more-24px' transform='translate(24 4) rotate(90)'>
              <path
                id='Path_36'
                data-name='Path 36'
                d='M16.8,9.2h0a.967.967,0,0,0-1.4,0L12,12.6,8.6,9.2a.967.967,0,0,0-1.4,0h0a.967.967,0,0,0,0,1.4L12,15.5l4.8-4.9A.967.967,0,0,0,16.8,9.2Z'
                fill='#5d5d5d'
              />
            </g>
          </svg>
        </div>
        <div
          className={`scroll-down ${canScrollRight ? 'active' : 'disabled'}`}
          onClick={() => {
            tryScroll(false);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <g id='arrow-more-24px' transform='translate(0 28 ) rotate(-90)'>
              <path
                id='Path_36'
                data-name='Path 36'
                d='M16.8,9.2h0a.967.967,0,0,0-1.4,0L12,12.6,8.6,9.2a.967.967,0,0,0-1.4,0h0a.967.967,0,0,0,0,1.4L12,15.5l4.8-4.9A.967.967,0,0,0,16.8,9.2Z'
                fill='#5d5d5d'
              />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
};

export default VirtualOfferContentTouchScreen;
