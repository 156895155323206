import { create } from 'zustand';
import produce from 'immer';

interface MoviesState {
  cachedMedia: { [key: string]: string };
  cachedMediaFetching: { [key: string]: boolean };
  getMediaFromCache: (url: string) => Promise<string | undefined>;
  playMuted: boolean;
  setPlayMuted: (muted: boolean) => void;
}

const useMovieStore = create<MoviesState>((set, get) => ({
  cachedMedia: {},
  playMuted: false,
  setPlayMuted: (muted: boolean) =>
    set(
      produce((state: MoviesState) => {
        state.playMuted = muted;
      })
    ),
  cachedMediaFetching: {},
  getMediaFromCache: async (remoteURL) => {
    let blobURL = get().cachedMedia[remoteURL];
    if (!blobURL) {
      setTimeout(async () => {
        console.debug(`Media is not in cache ${remoteURL}`);
        try {
          if (get().cachedMediaFetching[remoteURL]) {
            console.debug(`Already fetching media ${remoteURL}`);
            return;
          }
          console.debug(`Start fetching media: ${remoteURL}`);
          set(
            produce((state: MoviesState) => {
              state.cachedMediaFetching[remoteURL] = true;
            })
          );
          let response = await fetch(remoteURL);
          let blob = await response.blob();
          let url = URL.createObjectURL(blob);
          set(
            produce((state: MoviesState) => {
              state.cachedMedia[remoteURL] = url;
              state.cachedMediaFetching[remoteURL] = false;
            })
          );
        } catch (error) {
          console.error(`Error fetching media to cache ${error}`);
          set(
            produce((state: MoviesState) => {
              state.cachedMediaFetching[remoteURL] = false;
            })
          );
        }
      }, 100);
    }

    return blobURL;
  },
}));

export default useMovieStore;
