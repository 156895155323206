import React from 'react';
import { RootState } from '../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { toggleTicketExpanded } from '../store/ticket/ticket-actions';
import VirtualTicketEditorHeader from './VirtualTicket/VirtualTicketEditorHeader';
import VirtualTicketEditor from './VirtualTicket/VirtualTicketEditor';
import VirtualTicketTabs from './VirtualTicket/VirtualTicketTabs';
import PreparedVirtualTicketsHeader from './VirtualTicket/PreparedVirtualTicketsHeader';
import PreparedVirtualTicket from './VirtualTicket/PreparedVirtualTicket';
import { reloadApp } from '../hardware';

const VirtualTickets: React.FC<VirtualTicketReduxProps> = (props) => {
  const {
    isExpanded,
    dispatch,
    toggleExpanded,
    isShowingPreparedTickets,
    mobileAppTickets,
    tickets_count,
  } = props;

  const showTicket = isExpanded && !isShowingPreparedTickets;
  const showPreparedTickets = isExpanded && isShowingPreparedTickets;

  if (mobileAppTickets) {
    const title =
      tickets_count === 1
        ? '1 Cod pregătit'
        : `${tickets_count} Coduri pregătite`;
    return (
      <div
        className='fs-bg-shadow'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            padding: '24px 32px',
            background: '#212225',
            color: '#F8F8F8',
            width: 500,
            textAlign: 'center',
          }}
        >
          <div className='semibold text-20 mb-32'>{title}</div>
          <div
            style={{
              width: 200,
              height: 200,
              display: 'inline-flex',
              flexDirection: 'column',
              borderRadius: 100,
              backgroundColor: '#E5E5E5',
              objectFit: 'contain',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            <img
              style={{
                width: 64,
                height: 64,
                display: 'inline-block',
              }}
              src={mobileAppTickets.image}
              alt={mobileAppTickets.description}
            />

            <div className='inline-block text-dark text-20 mt-8 bold'>
              {mobileAppTickets.description}
            </div>
          </div>

          <div className='mt-16 mb-32 semibold'>TOUCHSCREEN 1</div>
          <div className='text-14 '>
            La casierie indicați numărul terminalului și imaginea de mai sus.
          </div>
          <div className='button mt-24' onClick={reloadApp}>
            Terminat
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isExpanded && <div className='fs-bg-shadow' onClick={toggleExpanded} />}
      <div className='virtualTickets'>
        <div className='ticket-header-wrapper'>
          {isExpanded && <VirtualTicketTabs />}
          {!isShowingPreparedTickets && <VirtualTicketEditorHeader />}
          {isShowingPreparedTickets && <PreparedVirtualTicketsHeader />}
        </div>
        <div className='vticket-wrapper'>
          {showTicket && <VirtualTicketEditor />}
          {showPreparedTickets && <PreparedVirtualTicket />}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    isExpanded,
    isShowingPreparedTickets,
    ticketsToApp,
    preparedTickets,
  } = state.virtualTicket;
  return {
    isExpanded,
    isShowingPreparedTickets,
    mobileAppTickets: ticketsToApp.session,
    tickets_count: preparedTickets.length,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
    toggleExpanded: () => dispatch(toggleTicketExpanded()),
  };
};
const virtualTicketConnector = connect(mapStateToProps, mapDispatchActions);
type VirtualTicketReduxProps = ConnectedProps<typeof virtualTicketConnector>;
const ConnectedVirtualTicket = virtualTicketConnector(VirtualTickets);
export default ConnectedVirtualTicket;
