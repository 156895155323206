import React, { Fragment, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import Calendar from './Calendar';
import CategoryFilter from './CategoryFilter';

import { toggleCheckTicket } from '../store/thunks';
import { ReactComponent as TaskIcon } from '../img/ic_task_black_24px.svg';
import VirtualOfferContentTouchScreen from './VirtualOfferContentTouchScreen';
import VirtualOfferContentTablet from './VirtualOfferContentTablet';
import WheelExclusion from '../img/Exclusion 3new.png';
import Wheel from '../img/ftn-wheel-new.png';
import { showWheel } from '../store/system/actions';
// import { showWheel } from '../../store/system/actions';

interface Props extends PropsFromRedux {
  isTouchscreen: boolean;
  onShowNewCheckTicket: () => void;
}

const Offer: React.FC<Props> = (props) => {
  const { isTouchscreen, onShowNewCheckTicket, showWheel } = props;
  const dispatch = useDispatch();

  const showCheckTicket = () => {
    onShowNewCheckTicket();
    dispatch(toggleCheckTicket());
  };

  return (
    <>
      <Calendar isTouchscreen={isTouchscreen} />
      {/* <ShowWheelButton onClick={showWheel} /> */}
      <CheckTicketButton onClick={showCheckTicket} />
      <CategoryFilter isTouchscreen={isTouchscreen} />

      {isTouchscreen && <VirtualOfferContentTouchScreen isTouchscreen />}
      {!isTouchscreen && <VirtualOfferContentTablet isTouchscreen={false} />}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
    showWheel: () => dispatch(showWheel()),
  };
};

const connector = connect(mapStateToProps, mapDispatchActions);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Offer);

const CheckTicketButton: React.FC<{ onClick: React.MouseEventHandler }> = ({
  onClick,
}) => {
  return (
    <div className='checkticket-button' onClick={onClick}>
      VERIFICA BILET
      <TaskIcon />
    </div>
  );
};

const ShowWheelButton: React.FC<{ onClick: React.MouseEventHandler }> = ({
  onClick,
}) => {
  return (
    <div className='wheel-button uppercase relative ' onClick={onClick}>
      <span className='inline-block px-0' style={{ letterSpacing: -0.01 }}>
        Învârte Roata
      </span>
      <div
        className='absolute inline-block overflow-hidden'
        style={{ right: -0, bottom: 0, height: 70, width: 96 }}
      >
        <div
          className='inline-block absolute '
          style={{ right: -47, bottom: -66, width: 133, zIndex: 100 }}
        >
          <div
            className='inline-block relative '
            style={{
              animation: '20s ease-in-out 0s infinite forwards wheel_spin',
            }}
          >
            <img src={Wheel} alt='Wheel' className='inline-block ' />
          </div>
        </div>
        <img
          src={WheelExclusion}
          alt='wheel'
          className='inline-block absolute'
          style={{
            top: 21,
            left: 28,
            zIndex: 24,
            transform: 'scale(1.4, 1.4)',
          }}
        />
      </div>
    </div>
  );
};
