/** @format */

// import { v4 } from 'uuid';
import { AppConfiguration } from '../../hardware';
import {
  ActionTypes,
  INIT_APP,
  UPDATE_CONFIG,
  UPDATE_PIXEL,
  RECEIVE_INITIAL_DATA,
  UPDATE_IS_CHECKING_TICKET,
  START_SESSION,
  TERMINATE_SESSION,
  PIXEL_PAIRED_ON_SERVER,
  SHOW_WHEEL,
  HIDE_WHEEL,
} from './types';

type SystemState = {
  uuid: string;
  loaded: boolean;
  connected: boolean;
  isCheckingTicket: boolean;
  isTouchscreen: boolean;
  sessionId?: number;
  config: AppConfiguration;
  pixelId: string | undefined;
  isPixelPairedOnServer: boolean;
  isShowingWheel: boolean;
};

export const initialState: SystemState = {
  uuid: '',
  loaded: false,
  connected: false,
  isCheckingTicket: false,
  isTouchscreen: false,
  pixelId: undefined,
  isPixelPairedOnServer: false,
  isShowingWheel: false,
  config: {
    ticketsAPI: '/',
    wssAPI: '/',
    idleTimeoutMovie: 180000,
    hasPixel: 0,
    printingAPI: '/',
    printingMode: 'none',
    printerHostname: '',
    playlist: undefined,
  },
};

export const systemReducer = (
  state = initialState,
  action: ActionTypes
): SystemState => {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        loaded: false,
        connected: false,
        uuid: action.meta.uuid,
        isCheckingTicket: false,
        isTouchscreen: action.meta.isTouchscreen,
        config: action.meta.config,
      };
    case UPDATE_CONFIG:
      return {
        ...state,
        config: action.meta.config,
      };
    case UPDATE_PIXEL:
      return {
        ...state,
        pixelId: action.meta._id,
      };
    case PIXEL_PAIRED_ON_SERVER:
      return {
        ...state,
        isPixelPairedOnServer: true,
      };
    case RECEIVE_INITIAL_DATA:
      return {
        ...state,
        loaded: true,
      };
    case UPDATE_IS_CHECKING_TICKET:
      return {
        ...state,
        isCheckingTicket: action.meta.value,
      };
    case START_SESSION:
      if (state.sessionId) {
        return state;
      }
      return {
        ...state,
        isShowingWheel: false,
        sessionId: Date.now(),
      };

    case TERMINATE_SESSION:
      return {
        ...state,
        isCheckingTicket: false,
        sessionId: undefined,
        isShowingWheel: false,
      };
    case SHOW_WHEEL:
      return {
        ...state,
        isShowingWheel: true,
      };
    case HIDE_WHEEL:
      return {
        ...state,
        isShowingWheel: false,
      };

    default:
      return state;
  }
};
