/** @format */

import { AppConfiguration } from '../../hardware';
import {
  INIT_APP,
  RECEIVE_INITIAL_DATA,
  UPDATE_IS_CHECKING_TICKET,
  ActionTypes,
  WS_CONNECT,
  WS_CONNECTED,
  WS_DISCONNECTED,
  WS_MESSAGE,
  START_SESSION,
  TERMINATE_SESSION,
  UPDATE_CONFIG,
  UPDATE_PIXEL,
  PIXEL_PAIRED_ON_SERVER,
  SHOW_WHEEL,
  HIDE_WHEEL,
} from './types';

export function initApp(
  uuid: string,
  isTouchscreen: boolean,
  config: AppConfiguration
): ActionTypes {
  return {
    type: INIT_APP,
    meta: {
      uuid,
      isTouchscreen,
      config,
    },
  };
}
export function updateConfig(config: AppConfiguration): ActionTypes {
  return {
    type: UPDATE_CONFIG,
    meta: {
      config: config,
    },
  };
}

export function updatePixel(_id: string): ActionTypes {
  return {
    type: UPDATE_PIXEL,
    meta: {
      _id,
    },
  };
}
export function pixelPairedOnServer(): ActionTypes {
  return {
    type: PIXEL_PAIRED_ON_SERVER,
  };
}

export function receiveInitialData(version: string): ActionTypes {
  return {
    type: RECEIVE_INITIAL_DATA,
    meta: {
      version,
    },
  };
}

export function updateIsCheckingTicket(value: boolean): ActionTypes {
  return {
    type: UPDATE_IS_CHECKING_TICKET,
    meta: {
      value,
    },
  };
}

export function wsConnected(): ActionTypes {
  return {
    type: WS_CONNECTED,
  };
}

export function wsConnect(url: string): ActionTypes {
  return {
    type: WS_CONNECT,
    meta: { url },
  };
}

export function wsDisconnected(reason = ''): ActionTypes {
  return {
    type: WS_DISCONNECTED,
    meta: { reason },
  };
}
export function wsMessage(message: any): ActionTypes {
  return {
    type: WS_MESSAGE,
    meta: { message },
  };
}

export function startSession(): ActionTypes {
  return { type: START_SESSION };
}
export function terminateSession(): ActionTypes {
  return { type: TERMINATE_SESSION };
}

export function showWheel(): ActionTypes {
  return { type: SHOW_WHEEL };
}
export function hideWheel(): ActionTypes {
  return { type: HIDE_WHEEL };
}
