import React, { useEffect, useState } from 'react';

import { RootState } from '../../store';
import {
  changeCurrentPreparedTicket,
  deletePreparedVirtualTicket,
} from '../../store/ticket/ticket-actions';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import VirtualTicketBetRow from './VirtualTicketBetRow';
import FullScreenMessageBox from '../FullScreenMessageBox';
import { getPrintingAPI } from '../../hardware';

const PreparedVirtualTicket: React.FC<ReduxProps> = (props) => {
  const {
    currentPreparedTicket,
    tickets,
    hasPrinterForTickets,
    hasAppForTickets,
    printerHostname,
    dispatch,
  } = props;
  const [showDeleteTicketConfirmation, setShowDeleteTicketConfirmation] =
    useState<string | undefined>(undefined);
  useEffect(() => {
    console.log(tickets);
  }, [tickets]);

  if (tickets.length === 0) {
    return null;
  } else {
    const { oids, bets, ticket_mode, groups } =
      tickets[currentPreparedTicket ?? 0];

    const handleChangeCurrentPreparedTicket = (
      e: React.SyntheticEvent<HTMLDivElement>
    ) => {
      const ticketIndex = Number(e.currentTarget.dataset.ticket);
      console.log('changing prepared ticket');
      dispatch(changeCurrentPreparedTicket(ticketIndex));
    };

    const handleDeleteTicket = (e: React.SyntheticEvent<HTMLDivElement>) => {
      const ticketCode = e.currentTarget.dataset.ticket;
      setShowDeleteTicketConfirmation(ticketCode);
    };

    const handlePrintTicket = async (
      e: React.SyntheticEvent<HTMLDivElement>
    ) => {
      const ticketCode = e.currentTarget.dataset.ticket;
      const api = getPrintingAPI();
      if (!api) {
        return;
      }
      console.log(`to print code: ${ticketCode}`);

      const ticketId = tickets.findIndex((t) => t.shortCode === ticketCode);
      if (ticketId < 0) {
        console.error('ticket not found');
        console.debug(tickets);
        return;
      }
      const ticket = tickets[ticketId];
      console.log(`found ticket ${ticket}`);
      const data = {
        hostname: printerHostname,
        barcode: ticket.ticketId,
        code: ticket.shortCode,
        date: ticket.prep_time,
        tip: ticket.ticket_mode_info,
        miza: `${ticket.miza}`,
        castig: `${ticket.castig}`,
        odds: `${ticket.totalOddString}`,
        mode: ticket.ticket_mode,
        groups: ticket.groups,
        bets: ticket.bets,
      };
      try {
        const res = await fetch(api, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
      } catch (err) {
        console.log('Error printing ticket');
      }
    };

    return (
      <>
        {showDeleteTicketConfirmation && (
          <FullScreenMessageBox
            message={`Ești sigur că vrei să ștergi biletul ${
              tickets[Number(showDeleteTicketConfirmation)].shortCode
            }?`}
            cancelLabel='Închide'
            continueLabel='Da'
            onCancel={() => setShowDeleteTicketConfirmation(undefined)}
            onContinue={() => {
              dispatch(
                deletePreparedVirtualTicket(
                  Number(showDeleteTicketConfirmation)
                )
              );
              setShowDeleteTicketConfirmation(undefined);
            }}
          />
        )}

        <div className='ticket-body flex-shrink-0' style={{ width: 527 }}>
          {ticket_mode !== 'COMBI' && (
            <>
              {oids.map((oid, index) => (
                <VirtualTicketBetRow
                  key={oid}
                  {...bets[oid]}
                  editable={false}
                  mode={ticket_mode}
                  roundedTop={index !== 0}
                />
              ))}
            </>
          )}
          {ticket_mode === 'COMBI' && (
            <>
              {groups.map((group) => (
                <div key={group.name} className='mb-24'>
                  <div className='h-36 lh-36 text-11 text-white semibold flex mb-1'>
                    <div className='inline-flex space-between flex-grow bg-gray-gradient rounded-t mr-1 px-12 box-border'>
                      <span>
                        GRUP <span className='text-yellow'>{group.name}</span>
                      </span>
                      <span>
                        {group.bets.length}{' '}
                        {group.bets.length !== 1 ? 'Pariuri' : 'Pariu'}
                      </span>
                    </div>
                    <div
                      className='inline-flex space-between bg-gray-gradient rounded-t px-12 box-border'
                      style={{ width: 111 }}
                    >
                      <span>Cota</span>
                      <span className='text-yellow'>{group.odd}</span>
                    </div>
                  </div>

                  {group.bets.map((oid, index) => (
                    <VirtualTicketBetRow
                      key={oid}
                      {...bets[oid]}
                      editable={false}
                      roundedTop={index !== 0}
                    />
                  ))}
                </div>
              ))}
            </>
          )}
        </div>
        <div
          className='inline-block flex-grow overflow-y-scroll p-24 box-content '
          style={{ width: 310 }}
        >
          {tickets.map((ticket, index) => (
            <div key={ticket.shortCode} className='w-full mb-24'>
              <div
                className={`pk py-8 ${
                  index === currentPreparedTicket
                    ? 'bg-yellow rounded-t'
                    : 'bg-white-gradient rounded-4'
                }`}
              >
                <div className='text-11 semibold '>
                  <span className='px-24'>{ticket.prep_time ?? ''}</span>
                  <span>{ticket.ticket_mode_info}</span>
                </div>
                <div
                  className='flex flex-row'
                  data-ticket={index}
                  onClick={handleChangeCurrentPreparedTicket}
                >
                  <div className='inline-block px-24 flex-grow self-end text-20 bold'>
                    {ticket.shortCode}
                  </div>
                  <div className='grid grid-col-2 grid-rows-2 mr-16 gap-4 text-right text-11 semibold'>
                    <div>Miza (lei):</div>
                    <div>{ticket.miza}</div>
                    <div>Castig posibil (lei):</div>
                    <div>{ticket.castig}</div>
                  </div>
                </div>
              </div>
              {index === currentPreparedTicket && (
                <div className={`mt-1 flex box-content`}>
                  <div
                    data-ticket={index}
                    className={`inline-block bg-gray ${
                      !hasPrinterForTickets ? 'flex-grow' : ''
                    } px-32 py-12 text-12 bold rounded-b mr-1 text-center`}
                    onClick={handleDeleteTicket}
                  >
                    Șterge
                  </div>
                  {hasPrinterForTickets && (
                    <div
                      data-ticket={ticket.shortCode}
                      className='flex-grow bg-yellow px-32 py-12 text-12 bold rounded-b text-center'
                      onClick={handlePrintTicket}
                    >
                      Printează
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    );
  }
};
const mapStateToProps = (state: RootState) => {
  const { preparedTickets, currentPreparedTicket } = state.virtualTicket;
  const { printingMode, printerHostname } = state.system.config;
  return {
    currentPreparedTicket,
    tickets: preparedTickets,
    hasPrinterForTickets: printingMode === 'printer',
    hasAppForTickets: printingMode === 'app',
    printerHostname,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};
const connector = connect(mapStateToProps, mapDispatchActions);

type ReduxProps = ConnectedProps<typeof connector>;

const ConnectedPreparedVirtualTicket = connector(PreparedVirtualTicket);
export default ConnectedPreparedVirtualTicket;
