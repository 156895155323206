import React, {
  SyntheticEvent,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';

import { connect } from 'react-redux';
import { RootState } from '../store';

// import styled from '@emotion/styled';
import * as selectors from '../store/selectors';
import { Tournament } from '../store/offer/types';
import { AnimateSharedLayout, LayoutGroup, motion } from 'framer-motion';

type Props = {
  children?: React.ReactChildren;
  tournaments?: Array<Tournament>;
  isTouchscreen: boolean;
  onScroll?: (id: string) => void;
};
type TargetWithValue = {
  value: string;
};

const TournamentNavigator: React.FC<Props> = (props: Props) => {
  const { tournaments, onScroll, isTouchscreen } = props;

  const [canScrollDown, setCanScrollDown] = useState(false);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const ul = React.useRef<HTMLUListElement>(null);

  const clickHandler = (e: SyntheticEvent<TargetWithValue>) => {
    if (onScroll) {
      onScroll(e.currentTarget.value);
    }
  };

  const onDidScroll: EventListener = (e) => {
    if (!ul.current) {
      return;
    }
    const theul = ul.current;

    const ot = theul.scrollTop;
    const sh = theul.scrollHeight;
    const h = theul.offsetHeight;

    setCanScrollUp(ot > 0);
    setCanScrollDown(ot + h < sh);
  };

  const tryScroll = (up: boolean) => {
    if (!ul.current) {
      return;
    }
    const theul = ul.current;
    const h = theul.offsetHeight;
    const options: ScrollToOptions = { behavior: 'smooth' };
    options.top = h * (up ? -1 : 1);

    theul.scrollBy(options);
  };

  useEffect(() => {
    const thisul = ul.current;

    thisul?.addEventListener('scroll', onDidScroll);
    onDidScroll(new MouseEvent('scroll'));
    return () => {
      thisul?.removeEventListener('scroll', onDidScroll);
    };
  }, [tournaments, isTouchscreen]);

  useLayoutEffect(() => {
    console.log('Tournaments navigator scroll to top');
    ul.current?.scrollTo({ top: 0 });
  }, [tournaments]);
  return (
    <div
      id='tournaments-navigator'
      className={isTouchscreen ? 'touchscreen' : 'tablet'}
    >
      <LayoutGroup id='a'>
        <ul ref={ul} className='scrollable'>
          {tournaments?.map((tournament) => (
            <motion.li
              key={tournament.id}
              initial={{ opacity: 0.9 }}
              animate={{
                opacity: 1,
                transition: { ease: 'easeIn', duration: 0.3 },
              }}
              exit={{
                opacity: 1,
                transition: { ease: 'easeOut', duration: 0.2 },
              }}
            >
              {/* <button className={`filter-item ${selected: "selected": ""}`} */}
              <button
                className='filter-item'
                value={tournament.id}
                onClick={clickHandler}
              >
                {tournament.name}
              </button>
            </motion.li>
          ))}
        </ul>
      </LayoutGroup>
      <div
        className={`scroll-controller ${
          !canScrollUp && !canScrollDown ? 'hidden' : ''
        }`}
      >
        <div
          className={`scroll-up ${canScrollUp ? 'active' : 'disabled'}`}
          onClick={() => {
            tryScroll(true);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <g id='arrow-more-24px' transform='translate(24 29) rotate(180)'>
              <path
                id='Path_36'
                data-name='Path 36'
                d='M16.8,9.2h0a.967.967,0,0,0-1.4,0L12,12.6,8.6,9.2a.967.967,0,0,0-1.4,0h0a.967.967,0,0,0,0,1.4L12,15.5l4.8-4.9A.967.967,0,0,0,16.8,9.2Z'
                fill='#5d5d5d'
              />
            </g>
          </svg>
        </div>
        <div
          className={`scroll-down ${canScrollDown ? 'active' : 'disabled'}`}
          onClick={() => {
            tryScroll(false);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <g id='arrow-more-24px' transform='translate(0 6 ) rotate(0)'>
              <path
                id='Path_36'
                data-name='Path 36'
                d='M16.8,9.2h0a.967.967,0,0,0-1.4,0L12,12.6,8.6,9.2a.967.967,0,0,0-1.4,0h0a.967.967,0,0,0,0,1.4L12,15.5l4.8-4.9A.967.967,0,0,0,16.8,9.2Z'
                fill='#5d5d5d'
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    tournaments: selectors.getSelectedTournaments(state),
  };
};

export default connect(mapStateToProps, null)(TournamentNavigator);
