import { isNumber } from 'lodash';
import React, { PropsWithChildren, useState } from 'react';
import { ReactComponent as BackSpace } from '../img/back-24px.svg';

const Key: React.FC<
  PropsWithChildren & {
    value: string;
    className?: string;
    onClick: (e: React.SyntheticEvent<HTMLElement>) => void;
  }
> = (props) => {
  const { children, value, onClick, className } = props;
  const [active, setActive] = React.useState(false);

  const label = children || value;

  const clickHandler = (e: React.SyntheticEvent<HTMLElement>) => {
    // setActive(true);
    onClick(e);
  };

  return (
    <div
      key={value}
      data-key={value}
      className={`key min-w-64 h-64 ${className || ''} ${
        active ? 'key-active' : ''
      }`}
      onClick={clickHandler}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      onMouseLeave={() => setActive(false)}
      onTouchStart={() => setActive(true)}
      onTouchEnd={() => setActive(false)}
    >
      {label}
    </div>
  );
};

export interface NumericKeyboardProps {
  onCancel?: () => void;
  onComplete?: (value: number) => void;
  initialValue: number;
  title: string;
}

const NumericKeyboard: React.FC<NumericKeyboardProps> = ({
  initialValue,
  onCancel,
  onComplete,
  title,
}) => {
  const [tempValue, setTempValue] = useState(`${initialValue}`);

  const completionHandler = () => {
    let value = Number(tempValue);

    if (isNaN(value)) {
      value = 0;
    }

    if (onComplete) {
      onComplete(value);
    }
  };

  const digitClickHandler = (e: React.SyntheticEvent<HTMLElement>) => {
    const key = e.currentTarget.dataset.key;

    const newValue = `${tempValue}${key}`;
    let numericValue = newValue;
    if (
      numericValue.length > 0 &&
      numericValue.charAt(numericValue.length - 1) === '.'
    ) {
      numericValue = `${numericValue}0`;
    }
    if (!isNaN(Number(numericValue))) {
      setTempValue(newValue);
    } else {
      console.log('not a number ', numericValue);
    }
  };

  const periodHandler = (e: React.SyntheticEvent<HTMLElement>) => {
    if (tempValue.indexOf('.') < 0) {
      setTempValue(`${tempValue}.`);
    }
  };

  const backspaceHandler = (e: React.SyntheticEvent<HTMLElement>) => {
    const key = e.currentTarget.dataset.key;

    const newValue = tempValue.slice(0, -1);
    let numericValue = newValue;
    if (
      numericValue.length > 0 &&
      numericValue.charAt(numericValue.length - 1) === '.'
    ) {
      numericValue = `${numericValue}0`;
    }
    if (!isNaN(Number(numericValue))) {
      setTempValue(newValue);
    } else {
      console.log('not a number ', numericValue);
    }
  };

  return (
    <div className='num-kb' style={{ width: 300 }}>
      <div className='text-16 semibold text-white py-16'>{title}</div>
      <div className='flex h-56 mb-16'>
        <div className='vactive-input flex-grow h-56 justify-end px-24 mr-4'>
          {tempValue}
        </div>
        <div className='key h-56' onClick={backspaceHandler}>
          <BackSpace />
        </div>
      </div>
      <div className='mb-48'></div>
      <div className='grid grid-cols-3 grid-rows-4 gap-8 m-auto mx-40 mb-48'>
        <Key value='7' data-key={7} onClick={digitClickHandler} />
        <Key value='8' data-key={8} onClick={digitClickHandler} />
        <Key value='9' data-key={9} onClick={digitClickHandler} />
        <Key value='4' data-key={4} onClick={digitClickHandler} />
        <Key value='5' data-key={5} onClick={digitClickHandler} />
        <Key value='6' data-key={6} onClick={digitClickHandler} />
        <Key value='1' data-key={1} onClick={digitClickHandler} />
        <Key value='2' data-key={2} onClick={digitClickHandler} />
        <Key value='3' data-key={3} onClick={digitClickHandler} />
        <Key
          className='col-span-2'
          value='0'
          data-key={0}
          onClick={digitClickHandler}
        />
        <Key className='' value='.' onClick={periodHandler} />
      </div>
      <div className='flex h-56'>
        <div
          className='vactive-input flex-grow h-56 justify-center px-24 bg-yellow text-bold text-16 uppercase'
          onClick={completionHandler}
        >
          Modifică
        </div>
      </div>
    </div>
  );

  //   const alfaNumericKeyPad = (
  //     <div className='keyboard'>
  //       <div className='keyboard-digits'>
  //         {DIGITS.map((d) => (
  //           <Key value={d} key={d} onClick={clickHandler} />
  //         ))}
  //       </div>

  //       {LETTERS.map((lrow, lrix) => (
  //         <div className='keyboard-letters' key={lrix}>
  //           {lrow.map((l) => (
  //             <Key
  //               key={l}
  //               value={l}
  //               onClick={clickHandler}
  //               className={`${l[0] === SHIFT_KEY ? 'key-shift' : ''}`}
  //             >
  //               {l[0] === SHIFT_KEY ? <ShiftIcon /> : caps ? l.toUpperCase() : l}
  //             </Key>
  //           ))}
  //         </div>
  //       ))}
  //     </div>
  //   );

  //   const numericKeyPad = (
  //     <div className='num-keyboard'>
  //       <div className='num-keyboard'>
  //         {DIGITS.map((d) => (
  //           <Key value={d} key={d} onClick={clickHandler} />
  //         ))}
  //       </div>
  //     </div>
  //   );
  //   return numeric ? numericKeyPad : alfaNumericKeyPad;
};

export default NumericKeyboard;
