/** @format */
import { StoreTypes } from '..';
import {
  initApp,
  receiveInitialData,
  updateIsCheckingTicket,
  wsConnect,
  startSession,
  updatePixel,
  terminateSession,
} from '../system/actions';
import {
  changeSelectedDate,
  updateToday,
  resetOffer,
  updateSelectedOdds,
  selectPendingOdd,
} from '../offer/actions';
import {
  sendTicketsToAppError,
  sendTicketsToAppComplete,
  sendTicketsToAppLoading,
  virtualTicketStartSession,
  virtualTicketUpdate,
  resetAllTickets,
} from '../ticket/ticket-actions';
import { OfferStore } from '../types';
import {
  getAppCredentials,
  resetAppCredentials,
  updateAppCredentials,
  Credentials,
  AppConfiguration,
  updateAppConfig,
  getTicketsAPI,
} from '../../hardware';
import { VirtualTicketState } from '../ticket/ticket-types';
import { wsMessage } from '../system/actions';
import { reloadApp } from '../../hardware';

const API_SYSTEM = process.env.REACT_APP_API;
const API_CC = process.env.REACT_APP_API_CC;

export const thunkInitApp =
  (isTouchscreen: boolean): StoreTypes.AppThunk =>
  async (dispatch, getState) => {
    // const appId = getAppCredentials()?.id;

    // const restrictedIDS: string[] = [
    //   '74f5f886-90a6-41f4-94f5-8805917fa75e',
    //   '2df70eab-461c-4497-be89-d661283e7594',
    // ];

    // if (appId && restrictedIDS.indexOf(appId) >= 0) {
    //   console.warn(`found restricted id ${appId}.. reloading`);
    //   resetAppCredentials();
    //   reloadApp();
    // }

    const authResult = await appAuth();

    updateAppCredentials(authResult.credentials);
    updateAppConfig(authResult.config);

    dispatch(wsConnect(authResult.config.wssAPI));
    if (authResult.credentials.id === undefined) {
      console.error('Invalid credentials');
      return;
    }

    if (authResult.config.hasPixel) {
      helloPixel(authResult.credentials.id ?? 'XXXZZZ').then(({ _id }) => {
        dispatch(updatePixel(_id));
        dispatch(serverPairPixel());
      });
    }

    dispatch(
      initApp(authResult.credentials.id, isTouchscreen, authResult.config)
    );

    dispatch(virtualTicketSessionStart());
    const retry = () => {
      return fetchInitialData().then(
        (data) => {
          dispatch(resetOffer(data));
          dispatch(receiveInitialData('01:23'));
          dispatch(updateToday());
          dispatch(setInitialDate());
          dispatch(startSession());
        },
        (error) => {
          console.error('error fetching data ', error);
          setTimeout(() => dispatch(retry), 2000);
        }
      );
    };
    retry();
  };

async function appAuth(): Promise<{
  credentials: Credentials;
  config: AppConfiguration;
}> {
  const credentials = getAppCredentials();
  let newCredentials: Credentials;
  let newConfig: AppConfiguration;

  return new Promise((resolve, reject) => {
    const retryFetch = async () => {
      try {
        const result = await fetch(`${API_CC}/auth`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(credentials),
        }).then((response) => response.json());
        newCredentials = result.credentials as Credentials;
        newConfig = result.config as AppConfiguration;
        resolve({ credentials: newCredentials, config: newConfig });
      } catch (err) {
        setTimeout(retryFetch, 2000);
      }
    };
    retryFetch();
  });
}

async function helloPixel(code: string): Promise<{ _id: string }> {
  return new Promise((resolve, reject) => {
    const retryFetch = async (interval: number) => {
      try {
        const result = await fetch(`http://localhost:8080/auth/identity`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code }),
        }).then((response) => response.json());
        const { _id } = result;

        if (!_id) {
          throw Error("pixel doesn't have an _id yet");
        }
        resolve({ _id: _id });
      } catch (err) {
        console.error('Hello pixel error', err);
        const delay = Math.min(2 * interval, 5 * 60 * 1000);
        setTimeout(() => retryFetch(delay), delay);
      }
    };
    retryFetch(1000);
  });
}

export const serverPairPixel =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const { isPixelPairedOnServer, pixelId } = state.system;
    if (isPixelPairedOnServer) {
      return;
    }
    dispatch(
      wsMessage({ type: 'pixelUpdate', id: pixelId, random: Date.now() })
    );
    setTimeout(() => {
      dispatch(serverPairPixel());
    }, 2000);
  };

// Standard variation
function fetchJSON<T>(url: string): Promise<T> {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

function fetchInitialData() {
  return fetchJSON<OfferStore.OfferData>(`${API_SYSTEM}offer.json`);
}

export const changeDate =
  (date: string): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    let category = `${state.offer.days[date].categories[0]}`;
    if (
      state.offer.days[date].categories.findIndex((c, i) => `${c}` === '3') >= 0
    ) {
      category = '3';
    }
    dispatch(changeSelectedDate(date, category));
  };

export const setInitialDate =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const date = state.offer.daysIds[0];
    let category = state.offer.days[date].categories[0];
    if (
      state.offer.days[date].categories.findIndex((c) => `${c}` === '3') >= 0
    ) {
      category = '3';
    }
    dispatch(changeSelectedDate(date, category));
  };

export const sendTicketToApp =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const { token, id } = getAppCredentials();
    const { retailAppAPI } = state.system.config;

    // const ticket_date = (time) => {

    // }

    const tickets = state.virtualTicket.preparedTickets.map((ticket) => ({
      code: ticket.shortCode,
      barcode: ticket.ticketId,
      miza: ticket.miza,
      castig: ticket.castig,
      bets: ticket.codes.length,
      type: ticket.ticket_mode_info,
      odds: ticket.totalOddString,
      created: ticket.date_created,
    }));

    dispatch(sendTicketsToAppLoading());
    const params = { id, token, tickets };
    console.log(params);
    fetch(`${retailAppAPI}/client/send_tickets_to_app`, {
      method: 'post',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((result) => {
        const { imageURL, imageDescription } = result;
        if (imageURL && imageDescription) {
          dispatch(sendTicketsToAppComplete(imageURL, imageDescription));
        }
      })
      .catch((err) => {
        dispatch(sendTicketsToAppError(`${err}`));
      });
  };

export const toggleCheckTicket =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const { isCheckingTicket } = state.system;
    dispatch(updateIsCheckingTicket(!isCheckingTicket));
  };

export const virtualTicketSessionStart =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    // const state = getState();
    fetch(`${getTicketsAPI()}/ticket/start-session/`, {
      method: 'POST',
      body: '',
    })
      .then((res) => res.json())
      .then((json) => {
        console.log('got json: ', json);
        const { cookies } = json;
        dispatch(virtualTicketStartSession(cookies));
      })
      .catch((error) => {
        console.error('error starting session: ', error);
      });
  };

export const virtualTicketAddBet =
  (
    code: string,
    oid: string,
    oddv: string,
    b_id: string
  ): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();
    if (getState().virtualTicket.ticketServerState.oids.indexOf(oid) >= 0) {
      dispatch(virtualTicketRemoveBet(code, oid, b_id));
      return;
    }
    document.dispatchEvent(new Event('click'));
    // dispatch(selectPendingOdd(b_id, code, oid, true));

    fetch(`${getTicketsAPI()}/ticket/bet/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        oid,
        oddv,
        sessionId,
        cookies,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log('got json: ', json);
        // dispatch(selectPendingOdd(b_id, code, oid, false));
        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });
        dispatch(updateSelectedOdds(betIds, oids));
        dispatch(virtualTicketUpdate(json));
      })
      .catch((error) => {
        console.error('error starting session: ', error);
        dispatch(selectPendingOdd(b_id, code, oid, false));
      });
  };

export const virtualTicketRemoveBet =
  (code: string, oid: string, b_id: string | undefined): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket },
    } = getState();
    const OP = `virtualTicketRemoveBet code: [${code}] oid: [${oid}] `;
    document.dispatchEvent(new Event('click'));
    if (undefined !== b_id) {
      // dispatch(selectPendingOdd(b_id, code, oid, true));
    }

    fetch(`${getTicketsAPI()}/ticket/bet/remove`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        oid,
        sessionId,
        cookies,
        ticket: currentTicket,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.debug(OP, 'got json: ', json);

        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        if (undefined !== b_id) {
          // dispatch(selectPendingOdd(b_id, code, oid, false));
        }
        dispatch(updateSelectedOdds(betIds, oids));
        dispatch(virtualTicketUpdate(json));
      })
      .catch((error) => {
        console.error(OP, error);
        if (undefined !== b_id) {
          dispatch(selectPendingOdd(b_id, code, oid, false));
        }
      });
  };

export const virtualTicketChangeMiza =
  (miza: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket },
    } = getState();
    const OP = `virtualTicketChangeMixa [${miza}] `;
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/miza`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ miza, sessionId, cookies, ticket: currentTicket }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.debug(OP, ' got json: ', json);
        dispatch(virtualTicketUpdate(json));
        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const virtualTicketPrepare =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId, uuid, isTouchscreen },
      virtualTicket: { cookies, currentTicket },
    } = getState();

    const OP = `virtualTicketPrepare`;
    document.dispatchEvent(new Event('click'));
    fetch(`${getTicketsAPI()}/ticket/prepare`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sessionId, cookies, ticket: currentTicket }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.debug(OP, 'got json: ', json);
        const now = new Date();
        const time =
          ('0' + now.getHours()).slice(-2) +
          ':' +
          ('0' + now.getMinutes()).slice(-2);
        if (json['ticket']) {
          json['ticket']['prep_time'] = time;
          json['ticket']['date_created'] = now;
        }

        dispatch(virtualTicketUpdate(json, true));
        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;

        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        dispatch(
          wsMessage({
            appId: uuid,
            sessionId,
            isTouchscreen,
            rx_action: {
              type: 'VT_PREPARED',
              code: data.shortCode,
              ticketId: data.ticketId,
              ticketMode: data.ticket_mode,
              ticketMiza: data.miza,
              ticketBets: oids.length,
              ticketCastig: data.castig,
              ticketActiveId: data.activeId,
            },
            dt: 0,
          })
        );

        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeCurrentTicket =
  (ticket: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();
    const OP = `changeCurrentTicket [${ticket}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/change-current`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        cookies,
        ticket,
        shopReady: shopReady[ticket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        dispatch(virtualTicketUpdate(json));
        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeTicketType =
  (type: string): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();
    const OP = `changeTicketType [${type}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/type`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        cookies,
        type,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeBetGroup =
  (code: string, oid: string, group: string): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();
    const OP = `changeBetGroup code: [${code}] oid: [${oid}] group: [${group}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/changebetsgroup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        cookies,
        code,
        oid,
        group,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeFixedBet =
  (code: string, oid: string, fixed: boolean): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();
    const OP = `changeFixedBet code: [${code}] oid: [${oid}] group: [${fixed}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/changefixedbet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        cookies,
        code,
        oid,
        fixed,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeSystemBetGroupChecked =
  (index: string, checked: boolean): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();
    const OP = `changeFixedBet index: [${index}] checked: [${checked}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/markbetgroup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        cookies,
        index,
        checked,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeSystemBetGroupValue =
  (index: string, value: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();
    const OP = `changeSystemBetGroupMiza index: [${index}] value: [${value}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/system-group-value`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        cookies,
        index,
        value,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeCombiBetGroupValue =
  (group: boolean, index: string, value: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();
    const OP = `changeCombiBetGroupValue index: [${index}] value: [${value}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/combi-group-value`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        cookies,
        index,
        value,
        group,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const virtualTicketResetMiza =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();
    const OP = 'virtualTicketResetMiza';
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/reset-miza`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        cookies,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const deleteCurrentVirtualTicket =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { cookies, currentTicket },
    } = getState();
    const OP = 'deleteCurrentVirtualTicket';
    document.dispatchEvent(new Event('click'));

    fetch(`${getTicketsAPI()}/ticket/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        cookies,
        ticket: currentTicket,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

// OFFER UPDATE

export const updateOffer = (): StoreTypes.AppThunk => (dispatch, getState) => {
  console.debug('Thunk updateOffer');
  dispatch(terminateSession());
  dispatch(setInitialDate());
  const retry = () => {
    console.debug('Thunk updateOffer fetching..');
    // performance.mark('downloadOffer');
    return fetchInitialData().then(
      (data) => {
        // console.log(
        //   performance.measure('thunk download offer', 'downloadOffer')
        // );
        // performance.mark('resetOffer');

        dispatch(resetOffer(data));
        // console.log(performance.measure('thunk reset offer', 'resetOffer'));
        // performance.mark('updateToday');
        dispatch(updateToday());
        // console.log(performance.measure('thunk updateToday', 'updateToday'));
        dispatch(setInitialDate());
        console.debug('Thunk updateOffer complete');
      },
      (error) => {
        console.error('error fetching data ', error);
        setTimeout(() => dispatch(retry), 2000);
      }
    );
  };
  setTimeout(() => retry(), 1200);
};

export const startNewSession =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId, isCheckingTicket },
      virtualTicket: { cookies, currentTicket },
    } = getState();

    if (isCheckingTicket) {
      dispatch(updateIsCheckingTicket(false));
    }
    dispatch(resetAllTickets());
    dispatch(virtualTicketSessionStart());
    dispatch(startSession());
    // dispatch(setInitialDate());
    console.debug('Thunk startNewSession started');
  };

// clear selected bets

export const showScanTicketThunk =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId },
    } = getState();

    if (sessionId === undefined) {
      dispatch(startSession());
    }
    console.log(`showScanTicketThunk`);
    console.log(`toggleCheckTicket `);
    dispatch(updateIsCheckingTicket(false));
    dispatch(updateIsCheckingTicket(true));
  };
