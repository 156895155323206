export enum TicketStatus {
  NOT_FINISHED = 'În desfășurare',
  WINNING = 'Tichet câștigător',
  LOSING = 'Tichet necâștigător',
}
export interface Ticket {
  vcode: string;
  complete: boolean;
  winner: boolean;
  wage: number;
  evtax: number;
  evwin: number;
  bonus1: number;
  bonus2: number;
  date: string;
  sn: string;
  totalbets: number;
  sistem: boolean;
  combinations: number[];
  fixes: number;
  isLotto: boolean;
  totalOdd: number;
  forPayout: number;
  status: TicketStatus;
}

export enum BetStatus {
  Accepted,
  NonWinning,
  Winning,
  Unresolved,
  Returned,
}

export class BetInfo {
  code = '';
  name = '';
  datetime = '';
  tip = '';
  odd = 0;
  ended = false;
  winner = false;
  index = 0;
}

export class SBTicket implements Ticket {
  bets: BetInfo[] = [];

  complete = false;
  winner = false;
  wage = 0.0;
  evtax = 0.0;
  evwin = 0.0;
  bonus1 = 0.0;
  bonus2 = 0.0;
  date = '';
  sn = '';
  vcode = '';
  totalbets = 0;
  totalTickets = 0;
  combinations = [];
  fixes = 0;
  isLotto = false;
  totalOdd = 0.0;
  forPayout = 0.0;
  sistem = false;
  status = TicketStatus.NOT_FINISHED;
}

export class LottoTicket implements Ticket {
  numbers: number[] = [];

  results: number[] = [];

  name = 'Lotto';

  code = '';

  vcode = '';

  complete = false;

  winner = false;

  wage = 0.0;

  evtax = 0.0;

  evwin = 0.0;

  bonus1 = 0.0;

  bonus2 = 0.0;

  date = '';

  sn = '';

  totalbets = 0;

  sistem = false;

  combinations = [];

  fixes = 0;

  isLotto = true;

  totalOdd = 0.0;

  forPayout = 0.0;

  status = TicketStatus.NOT_FINISHED;
}

export const makeTicket = (
  json: any,
  vcode: string
): SBTicket | LottoTicket | undefined => {
  let ticket: SBTicket | LottoTicket;

  switch (json.lototick) {
    case 1: {
      const ltt: LottoTicket = new LottoTicket();
      const tik = json.tick[0];
      ltt.sn = tik[0];
      ltt.complete = tik[1] === '1' || tik[1] === '0'; // winner or loser
      ltt.winner = tik[1] === '1';
      // storno tik[1] = 2
      ltt.name = tik[2];
      ltt.wage = parseFloat(tik[3]);
      ltt.totalOdd = Math.round((tik[4] / tik[3]) * 1000) / 1000;
      ltt.evwin = parseFloat(tik[4]);
      ltt.bonus1 = parseFloat(tik[5]);
      ltt.evtax = parseFloat(tik[6]);
      ltt.forPayout = ltt.evwin + ltt.bonus1 - ltt.evtax;
      // ltt.forPayout = parseFloat(tik[4]) + parseFloat(tik[5]) - parseFloat(tik[6]);
      ltt.date = tik[7];
      ltt.code = tik[8];
      ltt.numbers = tik[9].replace('{', '').replace('}', '').split(',');
      ltt.combinations = tik[10].replace('{', '').replace('}', '').split(',');
      ltt.sistem = ltt.combinations.length > 0;
      ltt.results = tik[11].replace('{', '').replace('}', '').split(',');
      ticket = ltt;
      ltt.status = ltt.complete
        ? ltt.winner
          ? TicketStatus.WINNING
          : TicketStatus.LOSING
        : TicketStatus.NOT_FINISHED;
      break;
    }
    case 0: {
      const sbt: SBTicket = new SBTicket();
      const tick = json.tick[0];
      sbt.sn = tick[0];
      sbt.complete = tick[1] === '1' || tick[1] === '0'; // winner or loser
      sbt.winner = tick[1] === '1';
      sbt.wage = parseFloat(tick[2]);
      sbt.evwin = parseFloat(tick[3]);
      sbt.bonus1 = parseFloat(tick[4]);
      sbt.bonus2 = parseFloat(tick[5]);
      sbt.evtax = parseFloat(tick[6]);
      sbt.date = tick[7];
      sbt.combinations = tick[10].replace('{', '').replace('}', '').split(',');
      sbt.fixes = parseFloat(tick[11]);
      sbt.totalTickets = parseFloat(tick[9]);
      sbt.forPayout = sbt.evwin + sbt.bonus1 + sbt.bonus2 - sbt.evtax;
      // parseFloat(data['tick'][0][3]) + parseFloat(data['tick'][0][4]) + parseFloat(data['tick'][0][5]) - parseFloat(data['tick'][0][6]);
      sbt.status = sbt.complete
        ? sbt.winner
          ? TicketStatus.WINNING
          : TicketStatus.LOSING
        : TicketStatus.NOT_FINISHED;

      ticket = sbt;

      const jsBet = json.bet;
      ticket.totalOdd = 1;
      ticket.bets = jsBet.map((jsb: string[]) => {
        const bet = new BetInfo();
        bet.ended = jsb[5] !== '0';
        const info = jsb[2].split('|');
        bet.winner = jsb[5] === '1';
        bet.odd = parseFloat(jsb[4]);
        bet.code = info[0];
        bet.name = info[1];
        bet.datetime = info[2];
        bet.tip = info[3];
        bet.index = parseFloat(jsb[6]);

        ticket.totalOdd *= bet.odd;
        return bet;
      });
      ticket.totalOdd = Math.round(ticket.totalOdd * 1000) / 1000;
      break;
    }
    default:
      return undefined;
  }
  ticket.vcode = vcode;
  return ticket;
};
