/** @format */

import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { throttle } from 'lodash';
import {
  setInitialDate,
  showScanTicketThunk,
  startNewSession,
  thunkInitApp,
  updateOffer,
} from './store/thunks';
import { RootState } from './store';
import Loading from './components/Loading';
import Offer from './components/Offer';
import './App.scss';
import TournamentDetails from './components/TournamentDetails';
import VirtualTickets from './components/VirtualTickets';
import CheckTicket from './components/CheckTicket/CheckTicket';
import { useWindowSize } from './components/CustomHooks';
import { hideWheel, wsMessage } from './store/system/actions';
import WheelOfFortune from './pages/WheelOfFortune';
import CachedMoviePlayer from './components/CachedMoviePlayer';

const INACTIVITY_TIMEOUT = Number(process.env.REACT_APP_INACTIVITY_TIMEOUT);

interface TicketBarcode {
  code: string;
  pin: string;
  virtual: boolean;
}

const App: React.FC = () => {
  const [idle, setIdle] = React.useState(0);
  const timer = React.useRef<number | undefined>(undefined);
  const updateOfferTimer = React.useRef<number | undefined>(undefined);
  const [showMovie, setShowingMovie] = React.useState(true);
  const [scannedTicket, setScannedTicket] = React.useState<
    TicketBarcode | undefined
  >(undefined);
  const barcode = useRef('');

  const {
    showTournamentDetails,
    loaded,
    isCheckingTicket,
    appId,
    sessionId,
    isShowingWheel,
  } = useSelector((state: RootState) => {
    return {
      showTournamentDetails: state.offer.selectedTournament,
      loaded: state.system.loaded,
      isCheckingTicket: state.system.isCheckingTicket,
      appId: state.system.uuid,
      sessionId: state.system.sessionId,
      isShowingWheel: state.system.isShowingWheel,
    };
  });

  const dispatch = useDispatch();

  const size = useWindowSize();
  const isTouchscreen = size.isTouchScreen;
  useEffect(() => {
    dispatch(thunkInitApp(isTouchscreen));
  }, [dispatch, isTouchscreen]);

  const logMovie = React.useCallback(() => {
    dispatch(
      wsMessage({
        appId,
        sessionId,
        isTouchscreen,
        rx_action: {
          type: 'SHOW_MOVIE',
        },
        dt: 0,
      })
    );
  }, [appId, dispatch, isTouchscreen, sessionId]);

  const logReloadApp = React.useCallback(() => {
    dispatch(
      wsMessage({
        appId,
        isTouchscreen,
        rx_action: {
          type: 'RELOAD_APP',
        },
        dt: 0,
      })
    );
  }, [appId, dispatch, isTouchscreen]);

  const handleRefreshForInactivity = () => {
    if (showMovie === true) {
      console.log('handleRefreshForInactivity ignore');
      window.clearTimeout(timer.current);
      return;
    }
    console.log('handleRefreshForInactivity active');
    dispatch(hideWheel()); // HIDE THE WHEEL BEFORE SHOWING THE MOVIE
    setShowingMovie(true);

    logMovie();
    dispatch(updateOffer());
  };

  useEffect(() => {
    console.log('useEffect setTimeout for handleRefreshForInactivity');
    window.clearTimeout(timer.current);
    timer.current = window.setTimeout(
      handleRefreshForInactivity,
      INACTIVITY_TIMEOUT
    );
    return () => {
      window.clearTimeout(timer.current);
    };
  }, [idle]);

  const updateOfferIfNeeded = () => {
    console.log('update offer in background:');
    if (showMovie === false) {
      return;
    }
    dispatch(updateOffer());
  };

  useEffect(() => {
    if (showMovie === true) {
      console.debug('enable offer background update');
      updateOfferTimer.current = window.setInterval(
        updateOfferIfNeeded,
        5 * 60 * 1000 // every 5 minutes
      );
    } else {
      console.debug('disable offer background update');
      window.clearInterval(updateOfferTimer.current);
    }
  }, [showMovie]);

  const updateIdle = (event?: Event) => {
    setIdle(new Date().getTime());
  };
  const throttledUpdateidle = throttle(updateIdle, 1000);

  const handleKeyDown = (e: KeyboardEvent) => {
    console.log(
      `New key ${e.key}, shift: ${e.shiftKey} - barcode was ${barcode.current} length: ${barcode.current.length}`
    );
    throttledUpdateidle();
    if (e.key === 'Enter') {
      console.log(
        `Enter Key: barcode is ${barcode.current} length: ${barcode.current.length}`
      );
      switch (barcode.current.length) {
        case 20: {
          console.info('Barcode scanned');
          const code = barcode.current;
          barcode.current = '';
          const scannedTicket = {
            code: code.substring(0, 16),
            pin: code.substring(16),
            virtual: false,
          };
          console.log(
            `Found barcode ${scannedTicket.code} ${scannedTicket.pin}`
          );
          setShowingMovie(false);
          setScannedTicket(scannedTicket);
          dispatch(showScanTicketThunk());
          break;
        }
        case 13: {
          console.info('Virtual Games Barcode scanned');
          const code = barcode.current.substring(0, 9);
          barcode.current = '';
          console.log(`Found barcode ${code}`);
          const scannedTicket = {
            code: code,
            pin: '',
            virtual: true,
          };
          setShowingMovie(false);
          setScannedTicket(scannedTicket);
          dispatch(showScanTicketThunk());
          break;
        }
        case 9: {
          console.info('Virtual Games Barcode scanned');
          const code = barcode.current;
          barcode.current = '';
          console.log(`Found barcode ${code}`);
          const scannedTicket = {
            code: code,
            pin: '',
            virtual: true,
          };
          setShowingMovie(false);
          setScannedTicket(scannedTicket);
          dispatch(showScanTicketThunk());
          break;
        }

        default: {
          barcode.current = '';
        }
      }
    } else {
      if (e.key.length === 1 && e.key.match(RegExp('[0-9A-z]'))) {
        barcode.current += `${e.key}`;
      }
    }
  };

  const handleWindowMessage = useCallback((event: MessageEvent) => {
    console.log(event);
    console.log(event.data);
    if (event.data === 'MSG_WHEEL_INTERACTION') {
      throttledUpdateidle(undefined);
    }
    if (event.data === 'MSG_WHEEL_FINISHED') {
      dispatch(hideWheel());
    }
  }, []);

  useEffect(() => {
    if (!loaded) {
      return () => {
        console.log(2 + 2);
      };
    }

    window.addEventListener('message', handleWindowMessage);
    document.addEventListener('click', throttledUpdateidle);
    document.addEventListener('touchmove', throttledUpdateidle);
    document.addEventListener('onmousedown', throttledUpdateidle);
    document.addEventListener('mousewheel', throttledUpdateidle);
    document.addEventListener('touchstart', throttledUpdateidle);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('message', handleWindowMessage);
      document.removeEventListener('click', throttledUpdateidle);
      document.removeEventListener('touchmove', throttledUpdateidle);
      document.removeEventListener('onmousedown', throttledUpdateidle);
      document.removeEventListener('mousewheel', throttledUpdateidle);
      document.removeEventListener('touchstart', throttledUpdateidle);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [loaded]);

  if (loaded)
    return (
      <>
        {isShowingWheel && <WheelOfFortune />}
        {showMovie && (
          <CachedMoviePlayer
            isTouchscreen={size.isTouchScreen}
            onInteraction={() => {
              console.debug('New session started');
              dispatch(startNewSession());
              dispatch(setInitialDate());
              setShowingMovie(false);
              setIdle(new Date().getTime());
            }}
          />
        )}

        <>
          <Offer
            isTouchscreen={size.isTouchScreen}
            onShowNewCheckTicket={() => setScannedTicket(undefined)}
          />
          {isCheckingTicket && (
            <CheckTicket
              isTouchScreen={size.isTouchScreen}
              receivedProtectionCode={scannedTicket?.pin}
              receivedTicketId={scannedTicket?.code}
              receivedTicketIsVirtual={scannedTicket?.virtual}
            />
          )}
          {showTournamentDetails && (
            <TournamentDetails isTouchscreen={size.isTouchScreen} />
          )}
          <VirtualTickets />
        </>
      </>
    );
  return <Loading />;
};

export default App;
