import React, { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as CloseXIcon } from '../../img/close-x-12px.svg';
import { ReactComponent as WhiteCheckedIcon } from '../../img/white-checkmark-24px.svg';
import { ReactComponent as WhiteXIcon } from '../../img/white-close-24px.svg';

import { TicketBet } from '../../store/ticket/ticket-types';
import {
  changeBetGroup,
  changeFixedBet,
  virtualTicketRemoveBet,
} from '../../store/thunks';
import { BetStatus } from '../../data/Ticket';

const TicketBetRow: React.FC<
  TicketBet & {
    editable: boolean;
    roundedTop?: boolean;
    mode?: string;
    extraClassName?: string;
    status?: BetStatus;
  }
> = (props) => {
  const {
    code,
    name,
    description,
    id,
    odd,
    otherOdds,
    groups,
    fixed,
    roundedTop,
    editable,
    mode,
    icon,
    extraClassName,
    status,
  } = props;
  const [isShowingChangeGroupOptions, setIsShowingChangeGroupOptions] =
    React.useState(false);
  const dispatchThunk = useDispatch();
  const removeEventHandler: React.MouseEventHandler = (e) => {
    console.log('Remove event handler');
    e.stopPropagation();

    const code = e.currentTarget.getAttribute('data-code') ?? '';
    const oid = e.currentTarget.getAttribute('data-oid') ?? '';

    dispatchThunk(virtualTicketRemoveBet(code, oid, undefined));
  };

  const handleShowChangeGroupOptions: React.MouseEventHandler = (e) => {
    console.log('handleShowChangeGroupOptions');
    e.stopPropagation();
    if (!editable) {
      return;
    }
    setIsShowingChangeGroupOptions(true);
  };

  const handleChangeGroup = (e: React.SyntheticEvent<HTMLDivElement>) => {
    console.log('handleChangeGroup');
    e.stopPropagation();

    setIsShowingChangeGroupOptions(false);
    const group = e.currentTarget.dataset.group;

    if (!group) {
      console.error('group is undefined');
      return;
    }
    dispatchThunk(changeBetGroup(code, id, group));
  };

  const handleFixedBet = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    console.log('handleFixedBet');
    e.stopPropagation();
    if (!editable) {
      return;
    }
    dispatchThunk(changeFixedBet(code, id, !fixed));
  };

  const rounded = roundedTop === true ? 'rounded-t' : '';

  const oddStyle: CSSProperties = {};
  switch (status) {
    case BetStatus.Winning: {
      oddStyle.backgroundColor = '#19FF84';
      break;
    }
    case BetStatus.Returned: {
      oddStyle.backgroundColor = '#19FF84';
      break;
    }
    case BetStatus.NonWinning: {
      oddStyle.backgroundColor = '#FFA89F';
      break;
    }
  }

  const oddTypeStyle: CSSProperties = {};
  if (status !== undefined) {
    oddTypeStyle.backgroundColor = '#D3D3D3';
  }

  const resultStyle: CSSProperties = { fill: 'white', color: 'white' };
  switch (status) {
    case BetStatus.Accepted: {
      resultStyle.visibility = 'hidden';
      break;
    }
    case BetStatus.Winning: {
      resultStyle.backgroundColor = '#00E068';
      break;
    }
    case BetStatus.NonWinning: {
      resultStyle.backgroundColor = '#FF240D';
      break;
    }
    case BetStatus.Returned: {
      resultStyle.backgroundColor = '#00E068';
      break;
    }
  }

  return (
    <div className={`ticket-bet ${extraClassName ? extraClassName : ''}`}>
      {mode === 'SYSTEM' && (
        <span
          className={`flex-shrink-0 bg-dark rounded-b text-13 semibold mr-1 shadow lh-30 text-center box-border w-52 ${rounded}`}
        >
          <span
            onClick={handleFixedBet}
            className={`bg-darkest px-2 inline-flex flex-shrink-0 justify-center  items-center rounded-11 lh-22 w-42 ${
              fixed ? 'flex-row' : 'flex-row-reverse'
            }`}
          >
            <span
              className={`text-center ${
                fixed ? 'text-yellow' : 'text-dark'
              } text-10 semibold flex-grow`}
            >
              fix
            </span>
            <span
              className={`inline-block ${
                fixed ? 'bg-yellow' : 'bg-gray-gradient'
              } h-18 w-18 rounded-9 `}
            ></span>
          </span>
        </span>
      )}
      {mode !== 'SYSTEM' && editable && (
        <>
          {!isShowingChangeGroupOptions && (
            <span
              className={`ticket-bet-group ${rounded}`}
              onClick={handleShowChangeGroupOptions}
            >
              {groups.find((g) => g.selected)?.name ?? ''}
            </span>
          )}

          {isShowingChangeGroupOptions && (
            <div className='inline-flex bg-gray items-end justify-center rounded text-13 semibold mr-1 shadow p-8 z-50 box-border w-52'>
              <div className=''>
                {groups.map((group, index) => (
                  <div
                    key={group.name}
                    onClick={handleChangeGroup}
                    data-group={group.name}
                    className={`bg-white py-8 px-12 w-64 text-center box-border ${
                      index === 0 ? 'rounded-t' : ''
                    } ${
                      index === groups.length - 1
                        ? 'rounded-b'
                        : 'border-solid border-b border-gray'
                    }`}
                  >
                    {group.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      <span className={`ticket-bet-code ${rounded}`}>{code}</span>
      <span className={`ticket-bet-text ${rounded}`}>
        <span className={`icon-sport ${icon}`} />
        <span className={`v-stack w-284`}>
          <span className='ticket-bet-name'>{name}</span>
          <span className='ticket-bet-type'>{description}</span>
        </span>
      </span>
      <span className={`ticket-odd-type ${rounded}`} style={oddTypeStyle}>
        {odd.name}
      </span>
      <span className={`ticket-odd-value ${rounded}`} style={oddStyle}>
        {odd.value}
      </span>
      {status && (
        <span
          className={`ticket-remove-betrow rounded-t `}
          data-code={code}
          data-oid={id}
          data-oddv={odd.value}
          onClick={removeEventHandler}
          style={resultStyle}
        >
          {status === BetStatus.Winning && <WhiteCheckedIcon />}
          {status === BetStatus.NonWinning && <WhiteXIcon />}
        </span>
      )}
      <span
        className={`ticket-remove-betrow rounded-t ${
          editable ? '' : 'display-none'
        }`}
        data-code={code}
        data-oid={id}
        data-oddv={odd.value}
        onClick={removeEventHandler}
      >
        <CloseXIcon />
      </span>
    </div>
  );
};

export default TicketBetRow;
